<template>
  <div class="pipa-list-wrap">
    <ul class="pipa-list" v-if="!status.loadingList || (status.loadingList && pipaData.pagination.pageIndex != 1)">
      <li class="pipa" v-for="pipa in pipaData.list" :key="pipa.id">
        <!-- 用户资料 -->
        <div class="user-info-wrap">
          <img :src="pipa.user.avatar">
          <div class="user-info">
            <div>
              <span>{{pipa.user.nickname}}</span>
              <UserMedals/>
            </div>
            <ul>
              <li>{{pipa | companyAndPositionText}}</li>
              <li>{{pipa.location}} · {{pipa.createTime | timeText}}</li>
            </ul>
          </div>
        </div>
        <!-- 内容 -->
        <div class="content-wrap">
          <div class="rich-content" v-html="pipa.content"></div>
          <ImageGrid9 v-if="pipa.images != null && pipa.images.length > 0" :data="pipa.images.map(item => imagePrefix + item.fileKey)"/>
          <MiniVideoPlayer v-if="pipa.video != null" :video="pipa.video"/>
        </div>
        <!-- 底部操作 -->
        <ul class="opera-wrap">
          <li>
            <Button type="text" theme="info" size="mini">
              <Icon type="icon-jubao"/>
              <span>举报</span>
            </Button>
          </li>
          <li>
            <Button type="text" theme="info" size="mini">
              <Icon type="icon-zhuanfa"/>
              <span>0</span>
            </Button>
          </li>
          <li>
            <Button type="text" theme="info" size="mini" @click="handleCommentClick(pipa)">
              <Icon type="icon-pinglun"/>
              <span>{{pipa.forwardCount}}</span>
            </Button>
          </li>
          <li>
            <Button type="text" theme="info" size="mini" @click="handleThumb(pipa)" :class="{'checked-up': pipa.isLike}">
              <Icon type="icon-dianzan" />
              <span style="position: relative; top: 1px;">{{pipa.likedCount}}</span>
            </Button>
          </li>
        </ul>
        <!-- 讨论区 -->
        <CommentWindow v-if="commentPipa != null && commentPipa.id === pipa.id" :target-data-serial-no="commentPipa.serialNo" :target-data-id="commentPipa.id" target-data-type="pipa"/>
      </li>
    </ul>
    <el-skeleton :rows="5" animated v-if="status.loadingList" />
    <div class="no-data" v-if="status.showAllData">-已加载完全部数据-</div>
    <!-- <Pagination
      :pagination="pipaData.pagination"
      @current-change="handleCurrentChange"
    /> -->
  </div>
</template>

<script>
import { fetchList, checkLikeApi } from '@/api/pipa'
import UserMedals from '@/components/common/usr/UserMedals'
import CommentWindow from '@/components/common/CommentWindow'
import ImageGrid9 from '@/components/common/ImageGrid9'
import MiniVideoPlayer from '@/components/common/MiniVideoPlayer'
import { debounce } from '@/utils/utils'
import BaseComponent from '@/components/base/BaseComponent'

export default {
  name: 'PipaList',
  extends: BaseComponent,
  components: { MiniVideoPlayer, ImageGrid9, CommentWindow, UserMedals },
  props: {
    // 用户ID
    userId: {
      default: null
    }
  },
  data () {
    return {
      status: {
        loadingList: false,
        showAllData: false, // 展示全部数据
        showNoData: false, // 暂无数据
        isLoadingLike: false // 点赞防抖
      },
      // 当前评论窗口pipa id
      commentPipa: null,
      // pipa数据
      pipaData: {
        list: [],
        pagination: {
          total: 0,
          pageIndex: 1,
          capacity: 20
        },
        params: {
          model: {
            userId: this.userId,
            groupId: null
          },
          sorts: [
            {
              direction: 'DESC',
              property: 'CREATE_TIME'
            }
          ]
        }
      },
      images: []
    }
  },
  filters: {
    // 公司/岗位
    companyAndPositionText (pipa) {
      const arr = []
      if (pipa.user.company != null && pipa.user.company !== '') {
        arr.push(pipa.user.company)
      }
      if (pipa.user.position != null && pipa.user.position !== '') {
        arr.push(pipa.user.position)
      }
      return arr.join('/')
    }
  },
  methods: {
    // 获取pipa列表
    fetchList () {
      if (this.status.loadingList || this.status.showNoData || this.status.showAllData) return
      this.status.loadingList = true
      fetchList({
        page: this.pipaData.pagination.pageIndex,
        capacity: this.pipaData.pagination.capacity,
        ...this.pipaData.params
      })
        .then(data => {
          if (this.pipaData.pagination.pageIndex === 1) {
            this.pipaData.list = []
            this.status.showNoData = false
            this.status.showAllData = false
          }
          // 暂无数据
          if (!data.total) {
            this.status.showNoData = true
            this.pipaData.list = []
            return
          }
          // 加载全部数据
          if (data.pageCount < data.page) {
            this.pipaData.pagination.pageIndex = data.pageCount
            this.status.showAllData = true
            return
          }
          this.pipaData.pagination.total = data.total
          this.pipaData.list = this.pipaData.list.concat(data.records)
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(_ => {
          this.status.loadingList = false
        })
    },
    // 分页
    handleCurrentChange (page) {
      this.pipaData.pagination.pageIndex = page
      this.fetchList()
    },
    // 点击评论
    handleCommentClick (pipa) {
      this.commentPipa = pipa
    },
    // 点赞
    async handleThumb (item) {
      if (this.isLoadingLike) return
      this.isLoadingLike = true
      await checkLikeApi(item.id)
      this.isLoadingLike = false
      this.pipaData.list.forEach((listItem, listIndex) => {
        if (listItem.id === item.id) {
          if (listItem.isLike) {
            listItem.isLike = !listItem.isLike
            listItem.likedCount -= 1
          } else {
            listItem.likedCount += 1
            listItem.isLike = true
          }
        }
      })
    }
  },
  created () {
    this.fetchList()
  },
  mounted () {
    const mainWrap = document.getElementsByTagName('main')[0]
    mainWrap.addEventListener('scroll', debounce(() => {
      const offsetHeight = mainWrap.offsetHeight
      const scrollTop = mainWrap.scrollTop
      const scrollHeight = mainWrap.scrollHeight
      if (offsetHeight + scrollTop >= scrollHeight) {
        this.pipaData.pagination.pageIndex += 1
        this.fetchList()
      }
    }, 600))
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.pipa-list-wrap {
  .pipa-list {
    list-style: none;
    padding: 0;
    margin: 0;
    .pipa {
      padding: 20px 20px 10px 20px;
      background-color: $background-color;
      margin-bottom: 10px;
      // 用户资料
      .user-info-wrap {
        box-sizing: border-box;
        display: flex;
        // 头像
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        // 资料
        .user-info {
          margin-left: 10px;
          div {
            display: flex;
            span {
              font-size: 16px;
              font-weight: bold;
              margin-right: 10px;
            }
          }
          & > ul {
            padding: 0;
            margin: 0;
            display: flex;
            li {
              margin-right: 10px;
              font-size: 12px;
              color: $font-color-third;
              &:first-of-type {
                margin: 0 5px 0 0;
                font-size: 14px;
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }
      // 内容
      .content-wrap {
        margin-top: 10px;
        padding-left: 50px;
        .pipa-mini-video-player {
          margin-top: 10px;
        }
      }
      // 底部操作
      .opera-wrap {
        padding: 0 20px;
        margin: 10px 0 0 0;
        display: flex;
        justify-content: right;
        li {
          margin-left: 20px;
          font-size: 17px;
          button {
            font-size: 12px;
            &:hover {
              background-color: $background-color;
              color: $font-color;
            }
            i {
              vertical-align: middle;
            }
            span {
              margin-left: 5px;
              vertical-align: middle;
            }
          }
          .checked-up {
            color: $primary-color;
            animation: scaleDraw 1s ease;
            &:hover {
              background: inherit;
              color: $primary-color !important;
            }
          }
        }
      }
    }
  }
  .no-data {
    line-height: 50px;
    text-align: center;
    color: #999;
  }
}

@keyframes scaleDraw{
  0% {
    transform: scale(1);  /*开始为原始大小*/
  }
  50% {
    transform: scale(1.2);
  }
}
</style>
