<template>
  <div class="user-pipa-list">
    <PipaList :user-id="userInfo.id"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PipaList from '@/components/common/pipa/PipaList'
export default {
  components: { PipaList },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.user-pipa-list {
  padding: 10px;
  /deep/ .pipa {
    border-bottom: 1px solid #eee;
  }
}
</style>
