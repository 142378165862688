import request from '@/utils/request'

// 查询pipa列表
export function fetchList (data) {
  return request.post('/pipa/page', data)
}

// 点赞
export function checkLikeApi (id) {
  return request.get(`/usr/like/pipa/${id}`)
}

// 查询圈子列表
export function fetchGroupListList (id) {
  return request.get('/basic/pipa/group/list')
}
