<template>
  <div class="pipa-mini-video-player" :style="{ width: width + 'px', height: height + 'px' }">
    <VideoPlayer :uri="previewUri" @click.native="visible = true" :controls="false" :muted="true" :autoplay="true" :loop="true"/>
    <!-- 放大窗口 -->
    <el-dialog
      :visible.sync="visible"
      :show-close="false"
      :append-to-body="true"
      custom-class="pipa-video-player-dialog"
    >
      <VideoPlayer
        v-if="visible"
        :qualities="qualities"
        :width="viewport.width"
        :height="viewport.height"
      />
      <span class="btn-close" @click="visible = false">
        <Icon type="el-icon-close"/>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VideoPlayer from '@/components/common/VideoPlayer'
import BaseComponent from '@/components/base/BaseComponent'
export default {
  name: 'MiniVideoPlayer',
  extends: BaseComponent,
  components: { VideoPlayer },
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState(['viewport']),
    // 视频宽度
    width () {
      if (this.video.width >= this.video.height) {
        return 400
      }
      return 200
    },
    // 视频高度
    height () {
      const width = this.width
      return this.video.height * width / this.video.width
    },
    // 预览视频地址
    previewUri () {
      if (this.video.smoothFileKey != null) {
        return this.videoPrefix + this.video.smoothFileKey
      }
      if (this.video.standardFileKey != null) {
        return this.videoPrefix + this.video.standardFileKey
      }
      if (this.video.hdFileKey != null) {
        return this.videoPrefix + this.video.hdFileKey
      }
      return this.videoPrefix + this.video.fileKey
    },
    // 清晰度
    qualities () {
      return {
        UHD: this.videoPrefix + this.video.fileKey,
        HD: this.video.hdUri == null ? null : (this.videoPrefix + this.video.hdFileKey),
        STD: this.video.standardUri == null ? null : (this.videoPrefix + this.video.standardFileKey),
        SMD: this.video.smoothUri == null ? null : (this.videoPrefix + this.video.smoothFileKey)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pipa-mini-video-player {
  display: inline-block;
}
</style>

<style lang="scss">
.pipa-video-player-dialog {
  background-color: transparent;
  box-shadow: none;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // 关闭按钮
    .btn-close {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 999;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, .3);
      border-radius: 50%;
      transition: all ease .3s;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, .6);
      }
      i {
        transition: all ease .15s;
        color: #fff;
        font-size: 26px;
      }
    }
  }
}
</style>
