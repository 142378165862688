<template>
  <ul class="image-grid9" :class="{ [modeClass]: true }">
    <li v-for="image in data" :key="image">
      <el-image :src="image" fit="cover" :preview-src-list="data"/>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ImageGrid9',
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    modeClass () {
      if (this.data.length === 1) {
        return 'size-1'
      }
      if (this.data.length === 3) {
        return 'size-9'
      }
      if (this.data.length < 5) {
        return 'size-4'
      }
      return 'size-9'
    }
  }
}
</script>

<style scoped lang="scss">
// 图片
.image-grid9 {
  padding: 0;
  margin: 10px 0 0 -2px;
  display: flex;
  width: 300px;
  flex-wrap: wrap;
  &.size-1 {
    li {
      width: 200px;
      height: 200px;
    }
  }
  &.size-4 {
    li {
      width: 150px;
      height: 150px;
    }
  }
  &.size-9 {
    li {
      width: 100px;
      height: 100px;
    }
  }
  li {
    padding: 2px;
    box-sizing: border-box;
    .el-image {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}
</style>
